<template>
<div class="container">
	<div>
		<p class="strong" style="text-align:center">三零荟平台用户注册及服务协议</p>
		<p>版本日期：2023年11月24日</p>
		
		<p>《三零荟平台用户注册及服务协议》（以下简称“本协议”）是用户（以下简称“您”）与上海叁個零科技有限公司（注册地址为：上海市金山区枫泾镇曹黎路38弄19号8091室，联系地址为：上海市虹口区中山北一路1230号B座4楼）及其关联公司（以下统称“我们”或“三零荟”）就使用“三零荟”微信小程序及/或App（以下简称“本平台”）并且购买本平台所提供的商品与/或使用本平台提供的服务等相关事宜订立的契约，就此构成对双方有约束力的法律文件。</p>
		
		<p>平台发布的所有规则文件，包括但不限于《叁零荟酒友会会员规则》、《叁零荟酒友会合伙人规则》等，均为本协议不可分割的组成部分。</p>
		
		<p>同意本协议是您进行本平台任何操作的先决条件。请您在购买本平台商品和/或使用本平台服务前认真阅读、充分理解本协议，特别是免除或者减轻平台责任等与您有重大利害关系的条款、法律适用和争议解决条款等，并确认能够接受和同意本协议下的全部规则后方才注册、登录、访问本平台、购买本平台商品和/或使用本平台服务。</p>
		
		<p>本平台不面向未成年用户。依据《中华人民共和国未成年人保护法》等法律法规，本平台不向未成年人出售任何酒品，亦不向未成年人展示酒类广告。如果您是未成年人，请不要以任何方式创建本平台账号、使用或访问“三零荟”平台以及在本平台购买商品和/或使用本平台服务。</p>
		
		<p>如您在阅读本协议过程中有任何疑惑需要咨询，您可通过本协议第十四条所述方式联系我们，我们会尽快为您做出解答。</p>
		
		<p class="strong">一、服务条款的确认和接纳 </p>
		
		<p>1、本平台向您提供的商品和/或服务包括但不限于您从本平台预定或购买酒或参与其他酒商品和/或服务相关的活动、购买、订单支付等具体服务，以及我们现在或将来还可能为您提供的其他商品和/或服务（以下统称“本平台商品和/或服务”）。</p>
		
		<p>2、凡以任何行为实际使用本平台（包括但不限于注册、登录、使用、浏览等）商品和/或服务，即表示您已仔细阅读并接受了本协议，尤其是本协议中包括但不限于个人信息保护和责任限制等条款在内的所有内容。当您购买本平台商品与/或使用本平台参与例如预定、购买、支付等活动时，您还需要阅读并接受与这些特定活动相关的规则（以下合称“服务条款”）。服务条款视为本协议不可分割的组成部分，与本协议具有同等法律效力。如果您不同意本协议或服务条款的内容，我们将无法为您提供本平台全部或者部分商品和/或服务。</p>
		
		<p>3、您在注册过程中勾选“同意”，或者实际购买，或者继续使用本平台商品和/或服务的，即表示您完全接受本协议。如您对本协议的任何部分存有任何异议，建议您终止注册和/或使用本平台。</p>
		
		<p>4、本平台提供跳转至访问第三方平台商品和/或服务的链接。如果您通过跳转链接前往第三方平台购买商品和/或使用第三方平台提供的服务，您需要理解并遵守第三方平台的用户协议、隐私政策，请您仔细阅读并谨慎选择。本平台和第三方平台分别就各自销售的商品和/或服务向您负责，互不承担连带责任，除非法律另有规定。本平台不就您从第三方平台购买/购买任何商品以及使用第三方平台的服务向您承担责任。您与第三方平台发生争议的，除非法律或者您和本平台达成的服务协议另有约定，您需要自行根据您和第三方平台之间达成的用户协议或其他条款直接向第三方平台主张解决。</p>
		
		<p>5、本协议各条款前如有所列索引关键词，仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体内容。</p>
		
		<p>6、如果您代表您个人，您确认，您已年满18周岁，具备完全民事权利能力和民事行为能力，能够独立承担法律责任。如果您不符合前述条件，请您停止访问/使用本平台并立即退出本平台。</p>
		
		<p>7、您提交购买商品和/或服务请求，即视为您满足上述条件且有真实的购买意愿，并会对订单中提供的所有信息的真实性负责。否则，本平台不能向您提供相关商品和/或服务，如给本平台、三零荟及关联公司或第三方造成损失的，您须承担相应的不利后果。</p>
		
		<p>8、您理解并同意，为了更好地为您提供商品和/或服务，本平台将对本协议或各项服务规则随时进行修改更新，一旦本协议或服务规则内容发生变动，本平台运营方将会在本平台上公布、通知修改后的内容。修改后的协议经过公示期后生效并代替原来的协议。如您不同意变更事项，您有权于变更事项确定的生效日前联系本平台反馈意见。如反馈意见得以采纳，本平台将酌情调整变更事项。如您对已生效的变更事项仍不同意的，您应当于变更事项确定生效之日起停止购买本平台商品和/或享用本平台服务，变更事项对您不产生效力；如您在变更事项生效后仍继续购买本平台商品和/或享用本平台服务，则视为您同意已生效的变更事项。</p>
		
		<p class="strong">二、定义</p>
		
		<p>1、“本平台”：指三零荟小程序及App以及随技术发展而出现的新的服务形态的运营平台，是由三零荟设立的官方电子商务自营平台。本平台向第三方平台提供互联网信息发布服务，向用户（买方）提供信息浏览、酒品购买、货款支付等服务。</p>
		
		<p>2、“三零荟授权线下门店”：指已获得三零荟官方授权并向购买成功的用户提供交付所购买三零荟酒服务的自营或由经营商运营的线下门店。</p>
		
		<p>3、“第三方平台”：指用户通过本平台提供的链接跳转访问由非三零荟运营的向用户销售第三方商品或服务的主体。</p>
		
		<p>4、“服务条款”：指在本平台上已经发布或将来可能发布的各种规范性文件，包括但不限于本协议、本协议附件、细则、服务条款、使用说明等及主页、商品页面或其它页面发布的常见问题说明、商品说明、通知、公告、其它文字说明等。</p>
		
		<p>5、“账号”：指用户根据本协议第三条的规定注册并使用，可以登录、访问本平台的用户账号，它将与用户提供的实名认证和手机信息进行绑定。</p>
		
		<p>6、“用户”：指凡认可本协议及服务规则，年满18周岁，具备完全民事权利能力和行为能力的自然人。</p>
		
		<p class="strong">三、用户账号</p>
		
		<p>1、您知悉并同意，您通过手机号、微信号等注册、通过验证后登录本平台界面，本平台将以此识别您的账号信息。您须对本平台发送的验证码严格保密，您需自行承担因验证码泄露造成的损失。与该账号相关的个人信息处理情况详见《三零荟隐私政策》。</p>
		
		<p>2、本平台只允许每位用户注册并使用一个平台账号。如有证据证明或平台根据自有规则判断您存在不当注册或不当使用平台多个账号的情形，平台可以直接采取冻结、封停账号、取消订单、拒绝提供服务等措施，如因此给本平台及相关方造成损失的，您应承担赔偿责任。</p>
		
		<p>3、一旦成功注册成为本平台用户，您应当妥善保管您的账号安全，您需自行承担因保管不善造成的损失。若您发现任何未经授权而使用您账号的行为或本平台可能存在安全漏洞的情况，请立即通知本平台。</p>
		
		<p>4、您申请成为本平台注册用户时，应向本平台提供真实、准确、完整、合法有效的个人信息。当您的个人信息发生变动时，应及时通知本平台进行更新。如果您提供的个人信息不真实、不准确、不完整，您须自行承担因此造成的责任及不利后果。本平台保留在法律允许的范围内拒绝提供服务、封停或注销您用户账号的权利。</p>
		
		<p>5、您应对您账号项下的所有行为结果（包括但不限于在线签署各类协议、购买商品、发布商品评分、参与购买、支付等）负责。</p>
		
		<p>6、您可以在任何时间停止使用本平台并申请注销平台账号以终止用户资格。在用户账号注销之前，用户应对剩余积分及时处理，兑换酒品或提现。在用户账号注销之后，我们将停止为您提供本平台商品和/或服务，并依法删除您的个人信息，但法律法规另有规定的除外。详情请参阅《三零荟隐私政策》。</p>
		
		<p class="strong">四、用户服务</p>
		
		<p>1、【服务内容】本平台为您提供您通过在本平台成功购买酒品后的快递物流或您前往三零荟授权线下门店自提所购买酒品的服务，及向您提供跳转至第三方平台购买其商品和/或服务链接。商品和/或服务就通过本平台销售的商品和/或服务而言，您收到或提取商品前，商品的所有权属于本平台或三零荟授权线下门店，您提取商品后，商品所有权归属于您。</p>
		
		<p>1.1 您有权在本平台浏览商品信息、通过本平台向三零荟授权线下门店购买商品、支付价款，提供评分等；</p>
		
		<p>1.2 您有权通过本平台跳转至第三方平台查看其发布的商品信息或进行购买；</p>
		
		<p>1.3 您有权在本平台查看账号下的个人信息和积分信息，有权应用本平台提供的功能进行操作；</p>
		
		<p>1.4 您有权按照本平台发布的活动规则参与本平台组织的线上线下活动；</p>
		
		<p>1.5 本平台承诺为您提供的其他商品和/或服务。</p>
		
		
		<p>2、【服务规则】您承诺遵守包括但不限于下列平台规则：</p>
		
		<p>2.1 您应当遵守法律法规、规章、规范性文件及政策要求的规定，不得在本平台或利用本平台商品和/或服务从事非法或其他损害本平台或第三方权益的活动，如不得利用非本人信息进行注册；不得损害他人公平的购买资格；不得利用软件、技术手段或其他破坏平台正常购买秩序的方式在三零荟平台套取商品或其他利益；不得实施滥用用户权利、影响三零荟平台正常经营秩序的行为；不得实施恶意售后行为，包括但不限于利用非原订单商品进行退换货的行为，利用非本人订单信息索取售后行为；不得发送或接收传销材料或传播其他危害的信息或言论；不得未经本平台授权使用或伪造本平台名义使用本平台电子邮件题头信息；不得怂恿、撺掇未成年人买酒、饮酒、假冒他人身份或者通过伪造他人身份证件注册登录本平台购买酒品；不得恶意评价本平台商品以故意损毁本平台形象和商誉，使本平台无端遭受负面评价等；不得实施本协议的其他约定或三零荟平台认定的其他不正当行为。</p>
		
		<p>2.2 您在未经本平台同意的情况下不得将本平台账号及验证码以赠与、借用、租用、转让或以其他方式处分、授权给他人。当您发现账号被未经授权的第三方使用或存在其他账号安全问题时应立即通知本平台，要求本平台暂停对该账号提供服务。您理解本平台对您提出的任何请求采取行动均需要合理时间，且本平台应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大。除本平台存在法定过错外，本平台不承担责任。</p>
		
		<p>2.3 除本协议或另有约定外，您理解并保证不利用在本平台上注册的账号进行假冒伪劣经营行为及其他未经本平台许可的行为，包括但不仅限于以下行为：</p>
		
		<p>（1）以销售或商业使用为目的，假冒三零荟平台上架产品，向他人或二手货买卖交易平台兜售假冒伪劣产品的行为；</p>
		
		<p>（2）其他违反法律法规规定的行为。</p>
		
		<p>2.4 您在本平台对已经提货的商品和/或服务进行评分时应当根据实际情况如实评分。</p>
		
		<p>2.5 我们将推出持续参与购买的奖励规则或购买专场等活动，具体活动规则详见平台页面展示。</p>
		
		<p>2.6您承诺在购买本平台商品和/或使用本平台服务的过程中应严格遵守法律法规、公序良俗及诚实信用原则；不得侵犯本平台及任何第三方的合法权益;不得扰乱平台的正常秩序；不得干扰本平台的正常功能，危害其网络系统安全；您亦不得实施包括但不限于以下行为：</p>
		
		<p>（1）以任何机器人软件、蜘蛛软件、爬虫、刷屏软件等非本平台认可的方式访问或登录平台，非法侵入本平台或他人网络、干扰本平台或他人网络正常功能、窃取网络数据等危害网络安全的活动。包括但不限于：出于任何目的，在未经授权的情况下使用、破坏或企图破坏、自动攻击、开发或滥用我们的资源或我们的网络安全保护措施，使用任何网络机器人、网络蜘蛛(Spiders)、抓取工具、抓宠程序、拟人程序或其他非真实用户或避开、破坏技术措施等非正常的自动手段读取、复制、转存访问本平台的任何内容，未经本平台许可使用插件、外挂或者通过其他第三方工具、运营平台或任何服务接入本平台和相关系统；对本平台的网络服务及相关软硬件设施进行破解、破坏、删除、修改或者增加，对计算机信息网络中存储或者传输的数据和应用程序进行删除、修改或者增加的；上传、复制、传送、传播任何干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，干扰或破坏本平台或与提供本平台相连的服务器和网络；向他人提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助的行为；其他违反法律法规，危害计算机网络安全的行为；</p>
		
		<p>（2）通过任何方式对本平台造成或可能造成不合理或不合比例的重大负荷；</p>
		
		<p>（3）通过发送病毒、木马等行为攻击其他用户账号或终端设备；</p>
		
		<p>（4）协助他人获取不正当利益；</p>
		
		<p>（5）教唆他人从事本协议所禁止的行为；</p>
		
		<p>（6）冒充本平台工作人员欺骗其他用户；</p>
		
		<p>（7）其他违反法律法规、公序良俗或诚实信用原则的行为。</p>
		
		<p>您理解并同意，您的购买行为应当基于真实的且合理的消费需求，不得存在对商品和/或服务实施恶意购买、恶意维权等扰乱本平台正常交易秩序的行为。基于维护本平台交易秩序及交易安全的需要，本平台发现上述情形时有权主动执行关闭相关交易订单、暂停或注销用户账号等操作。</p>
		
		<p>2.7 若您未遵守以上2.5规定的，本平台运营方有权判定您的行为是否符合本协议约定以及平台规则，并视行为情节有权限制或终止向您提供商品和/或服务（包括但不限于暂停或注销用户的账号、限制购买资格等措施）、取消您在特定活动中的参与资格、取消交易订单、通知三零荟物流部或授权线下门店停止发货等措施，直至注销用户的账号。同时本平台运营方保留向有关政府部门举报或向人民法院或仲裁机构申请诉讼或仲裁的权利，您需承担由此导致的全部法律责任。此外，您的行为若给本平台或者三零荟授权线下门店造成损失的，本平台或者三零荟授权线下门店均有权要求您停止损害、赔礼道歉、赔偿损失、承担违约责任等。</p>
		
		<p class="strong">五、交易规则</p>
		
		<p>您承诺在其访问、使用本平台服务的过程中应当遵守如下平台交易规则。</p>
		
		<p>1、【浏览商品和/或服务信息】</p>
		
		<p>您在本平台浏览商品或购买商品时，应当仔细阅读商品和/或服务信息中包含的全部内容，包括但不限于商品和/或服务的名称、规格、保质期、储藏方式、数量、价格、等内容，其中您应特别注意商品和/或服务的价格、规格及数量等内容，您应在认真阅读、理解并完全接受商品和/或服务信息中包含的全部内容后方可点击购买（或“支付”等类似操作，下同）。</p>
		
		<p>本平台的商品和/或服务信息随时都有可能发生变动，本平台页面中的文字表述、图片效果可能被实时修改和调整，对于此类变化和调整，本平台内可能将不作特别通知。本平台内展示的商品和/或服务规格参数、功能说明、限购数量等商品和/或服务信息将尽可能准确、详细并与实际商品和/或服务信息相匹配，但由于本平台上商品和/或服务信息的数量庞大，且受互联网技术发展水平、商品批次和生产供应因素的实时变化等因素的限制，本平台不排除部分信息会存在滞后或差错的可能性。您确认：对此情形您表示知悉、理解并同意，承诺您不会对此要求本平台承担相关责任。</p>
		
		<p>2、【提交购买或下达服务订单】</p>
		
		<p>2.1 您在购买开放时段点击购买后或者点击其他在线普通商品，可以进入商品和/或服务购买或下单页面（具体页面内容及标题可能因客户端版本不同，在不同时期而有所差异）。您应当仔细阅读商品和/或服务购买页面中所包含的全部内容，包括但不限于商品和/或服务信息中的全部内容、为再次提示您注意而标明的所购买商品和/或服务内容（如有），选择及确认购买商品和/或服务的名称、数量、价格、发货地或线下提货门店、接收货物的收货地址、收货人姓名、收货人手机号码、应付酒款总额等内容。您需配合提供最新、真实、完整、有效的信息，以完成您所选酒品的购买流程，但我们无法确保您的购买（预定）一定成功。与购买相关内容与具体要求，请参考购买规则。您需要务必保证您填写的收货人姓名与您购买或提交订单实名认证时提供的姓名相符。</p>
		
		<p>本平台对商品进行明码标价（会员价按会员规则执行），但您应当了解并同意，本平台的商品和/或服务价格信息随时都有可能发生变动，因此商品和/或服务成交价格须以您被通知购买成功需要支付时的价格为准。同时，尽管本平台会尽最大努力确保本平台商品和/或服务价格的准确性，但仍然可能出现部分商品和/或服务标价错误的情形，对于明显错误标价的商品和/或服务，本平台保留不予确认或取消相应订单的权利。</p>
		
		<p>2.2 您下单时须选择希望购买的商品及数量、价款。同时，为向本平台用户提供公平的购买资格与价格，防止恶意抢购、哄抬商品价格，以及防止未成年人购买酒品，您还需要通过实名认证，通过核验后才有权参与购买活动或购买其他普通商品。您获得购买机会后，可以选择在本平台上进行线上支付或者去由我们授权的线下门店进行付款。为了保证支付方为购买者本人，您需要完成实人认证的流程。有关本平台提供实名认证和实人认证（统称“身份认证”）服务的相关内容与具体要求，请参考三零荟身份认证相关协议；您可以随时登录本平台账号，查询购买状态。</p>
		
		<p>2.3 当您代表个人从本平台购买商品和/或服务用于个人消费的，您在提交购买请求前应再次阅读并确认参与购买商品和/或服务的全部内容后方可点击确认购买，您点击商品购买按键即视为您已知晓、同意并接受平台购买规则中的全部内容并受之约束。</p>
		
		<p>2.4商品和/或服务结算订单中所包含的全部内容即为商品和/或服务购买合同的内容，具体包括但不限于商品和/或服务的名称、规格储藏方式数量、价格、售后服务，及您填写的商品和/或服务数量、价款及支付方式、提货方式等。购买合同关系一经成立，您与三零荟平台及其授权线下门店均应当按照前述商品购买合同的约定行使各自的权利和履行各自的义务。</p>
		
		<p>2.5 您理解并同意，尽管本平台尽力满足所有用户的需求并尽商业上的合理努力确保商品和/或服务供应，但由于市场变化及各种以合理商业努力难以控制因素的影响，如由于生产、物流等因素会导致本平台无法保证可以随时准确提供商品和/或服务库存信息，在特殊情况下，您在线付款之后也可能会存在库存不足、不可抗力等导致的履约不能的可能。如遇上述情况，在合同成立前，本平台展示的库存信息仅仅是当时本平台可能供货的参考数量，本平台将在合同成立后根据实际供货情况另行通知您商品和/或服务的提货期限。如果您认为提货期限太长，本平台、三零荟授权线下门店将及时与您联系，您有权取消订单，本平台、三零荟授权线下门店在征得您同意情况下亦有权取消订单或待履行不能因素消失后继续履行。如果三零荟授权线下门店无法供货的，本平台将通知您取消订单。订单被取消后，如您已付款，本平台将为您办理退款手续，交易价款将原路返还您付款账号。</p>
		
		<p>2.6 本平台或者三零荟授权线下门店有权在发现本平台显示的商品和/或服务或订单有明显错误、重大瑕疵（即本平台显示的商品价格被错误标示为明显低于会员价）的情况下，单方面撤回这些信息或在合同关系成立的情况下撤销该订单。您已付款的，交易价款将原路返还用户付款账号。本平台或者三零荟授权线下门店保留对商品订购数量的限制权。</p>
		
		<p>2.7 订单按照支付顺序发货或提货，预计发货或提货时间在支付全部完成前可能变更。预计发货或提货时间根据商品和/或服务供应库存信息计算得出，受诸多因素影响可能会产生偏差。</p>
		
		<p>3、【支付商品和/或服务价款】</p>
		
		<p>您及时、足额地支付购买商品和/或服务所需的价款是您收货/提货的前提。您应根据付款页面的提示通过网上支付平台完成商品和/或服务价款的支付，或者您在三零荟授权线下门店提货时按照门店要求完成支付。除发货或提货时付款的商品和/或服务或另有特别明示说明外（如有），您在支付商品和/或服务价款之前无权要求三零荟授权线下门店向您提供商品和/或服务。您购买成功后，如未能按照选择的支付方式在指定时间内（如团购）完成付款，您的该笔订单将失效，您无法再支付或据此提货，并且您的当次购买权益也由此失效。如您希望再次购买本平台商品，您需要重新启动购买流程。</p>
		
		<p>4、【开票】</p>
		
		<p>除另有特别说明外,平台展示的商品和/或服务价格均为含税价格。商品和/或服务税务发票应由平台商家或三零荟授权线下门店向您开具。</p>
		
		<p>5、【物流配送/提货】</p>
		
		<p>5.1 请您理解并知悉，对于您的同一笔订单，无论购买商品的数量多少，您只能选择物流配送或者现场提货一种方式作为收货方式。</p>
		
		<p>5.2 如果您选择到三零荟授权线下门店现场提货的，您在提货时应仔细确认所购买商品和/或服务的名称、价格、数量、规格、联系地址、电话、收货人及服务的内容、时间、限制性等要求，仔细核实所选择的提货点等信息。您应当在订单显示的提货时间前往提货点，凭借本平台生成的订单信息及与本平台账号实名认证相符的有效身份证件完成线下提货。</p>
		
		<p>5.3 如果您选择物流配送，为了快速准确地进行酒品配送，您需填写详细准确的收货人姓名、收货人电话、收货地址等信息，并保持通信工具畅通。请您在收货后及时查验所收货物，如发现任何问题，请在24小时内联系我们，详见平台在线客服及客服电话。如果因为您填写的收货人姓名、收货人电话、收货地址等信息错误，导致将货物交付给非您本人的，您将自行承担因此而造成的损失。</p>
		
		<p>5.4 您在收到商品时应仔细确认所购买商品和/或服务的名称、价格、数量、规格、联系地址、电话、收货人及服务的内容、时间、限制性等要求，并确保由您本人收货。您应当凭借本平台生成的订单信息及与本平台账号实名认证相符的有效身份证件完成签收。</p>
		
		<p>5.5 对于购买成功的订单，我们按照支付顺序安排发货，预计发货时间在支付全部完成前可能变更。预计发货时间根据酒品供应库存信息计算得出，受诸多因素影响可能会产生偏差。请您知悉并理解，相同或类似的收货地址，同一联系方式，同一付款账号，同一收货人的订单可能不会同时发货。</p>
		
		<p>5.6 因如下情况造成订单延迟配送或无法配送的，订单可能失效，本平台不承担配送责任：</p>
		
		<p>5.6.1 您提供的收货信息不实、不准确或不完整；</p>
		
		<p>5.6.2 货物送达收货地址后无人签收；</p>
		
		<p>5.6.3 经两天内多次联系仍然无法和收货人取得联系的，如收货人电话始终处于停机或无人接听状态；</p>
		
		<p>5.6.4 第三方原因或不可抗力因素导致的，例如：自然灾害、疫情、交通戒严、骚乱、突发战争等。</p>
		
		<p>订单失效后，如您希望再次购买本平台商品，您需要重新启动购买流程。订单款项将按原支付路径返还您，具体到账日期将以支付渠道实际到账周期为准。</p>
		
		<p>6.【交易争议处理】</p>
		
		<p>6.1.您在本交易过程中与三零荟或其授权线下门店发生争议的，您或其他任何一方均有权选择以下途径解决：</p>
		
		<p>（1）与争议相对方自主协商；</p>
		
		<p>（2）使用本平台提供的客服热线、在线客服或意见反馈进行争议调处服务；</p>
		
		<p>（3）请求消费者协会或者其他依法成立的调解组织调解；</p>
		
		<p>（4）向有关行政部门投诉；</p>
		
		<p>（5）向三零荟授权线下门店或三零荟平台公司所在地人民法院提起诉讼。</p>
		
		<p>6.2.如您依据本协议使用本平台的争议调处服务，则表示您认可并愿意履行本平台的客服作为独立的第三方根据其所了解到的争议事实并依据本规则所作出的调处决定（包括调整相关订单的交易状态、判定将争议款项的全部或部分支付给交易一方或双方等）。在本调处决定作出前，您可选择本条第6.1款第（3）（4）（5）项所述途径（下称“其他争议处理途径”）解决争议，以中止本平台的争议调处服务。如您对调处决定不满意，您仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，您仍应先履行调处决定。</p>
		
		<p class="strong">六、用户的权利和义务</p>
		
		<p>1．您有权按照本协议约定接受本平台提供的本协议项下服务。</p>
		
		<p>2．您使用本平台必须符合国家法律法规，您承诺，绝对不会通过本平台发送或传播违反国家法律法规及社会公序良俗的信息，此类信息包括但不限于：</p>
		
		<p>2.1 反对宪法所确定的基本原则的；</p>
		
		<p>2.2 危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、损害国家荣誉和利益的；</p>
		
		<p>2.3 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
		
		<p>2.4 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
		
		<p>2.5 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
		
		<p>2.6 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
		
		<p>2.7 恐吓、侮辱、攻击、诽谤他人，侵害他人合法权益的；</p>
		
		<p>2.8 利用本平台从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动的；</p>
		
		<p>2.9 发布任何侵犯他人著作权、商标权等知识产权及其他合法权利的；</p>
		
		<p>2.10 含有法律、行政法规禁止的其他内容的。</p>
		
		<p>3．如您发现收到或在三零荟授权线下门店提取的商品和/或服务与订单内容不符或存在质量问题的，应及时向三零荟或其授权线下门店提出，双方进行友好协商解决。</p>
		
		<p>4．您应妥善保管注册/登录本平台的账号、账号绑定的手机号码，与本平台账号关联的支付账号，及来自于本平台有关的一切信息和设备。如您更换、暂停或终止使用上述信息和/或设备，或遗失、泄露前述信息和/或设备的，您应及时通知本平台并及时联系本平台进行解绑或注销处理，以减少可能发生的损失。您理解本平台对您的任何请求采取行动均需要合理时间，且本平台应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大。无论是否已通知本平台，除本平台存在法定过错外，因上述原因所致损失由您自行承担，本平台不承担责任。凡使用账号、验证码进行交易、提出交易条件或做出其它意思表示的，均视为您的意思表示，因此产生的交易效果由您承担。除非您在交易前已有效通知本平台挂失账号。</p>
		
		<p>5．您可以根据本协议规定注销在本平台的账号。除法律法规另有规定外，注销账号之后本平台将停止为您提供服务，并根据法律规定或协议约定删除您个人信息，除非法律法规另有规定。详情请查阅《三零荟隐私政策》。</p>
		
		<p>6．访问或使用本平台时，您必须自行配备接入互联网所需上网设备，自行负担上网服务发生的各项费用，包括并不限于网络接入费、手机流量费等。当涉及使用任何第三方服务时，您还应遵守第三方关于服务规则和相关费用的规定。本平台有权出于自身商业决定、本平台维护需要等原因，在法律允许范围内调整、更改、暂停本平台的服务。您完全认可本平台的这项权利，并不会就此向本平台主张赔偿。</p>
		
		<p>7．若您违反法律法规或本协议约定，本平台有权视情节的严重程度，采取警告、限期改正、暂停服务、取消订单、单次或永久限制购买、禁止交易、关闭账号、永久禁止注册、暂停或者永久注销账号等措施。如给本平台或第三方造成损失的，您应负责依法赔偿。您的行为构成犯罪的，本平台有权主动检举揭发您或协助政府部门对您进行的查处。</p>
		
		<p class="strong">七、本平台的权利和义务</p>
		
		<p>1．如您不具备本协议约定的注册资格，则本平台有权拒绝您进行注册，对已注册的您有权注销其账号，本平台因此而遭受损失的，本平台有权向前述您或其法定代理人主张赔偿。同时，本平台保留其他任何情况下决定是否接受您注册的权利。</p>
		
		<p>2．本平台发现账号使用者并非账号初始注册人时，有权中止该账号的使用。</p>
		
		<p>3．本平台通过技术检测、人工抽检等检测方式合理怀疑您提供的信息错误、不实、失效或不完整时，有权通知您更正、更新信息或中止、终止为其提供本平台商品和/或服务。</p>
		
		<p>4．本平台有权在发现本平台上显示的任何信息存在明显错误时，对信息予以更正。</p>
		
		<p>5．本平台保留修改、中止或终止本平台商品和/或服务的权利，本平台行使前述权利将按照法律规定的程序及方式告知您。</p>
		
		<p>6．本平台应当采取必要的技术手段和管理措施保障本平台的正常运行，并提供必要、可靠的交易环境和交易服务，维护交易秩序。</p>
		
		<p>7．根据法律法规要求，本平台有权在本协议履行期间及本协议终止后按法律法规的规定保留您使用本平台商品和/或服务期间的商品和/或服务信息、交易信息。</p>
		
		<p class="strong">八、用户评分</p>
		
		<p>1．您在购买成功并完成提货后可以根据实际情况在本平台发布评分。</p>
		
		<p>2．本平台不会删除涉及平台内销售的商品或提供的服务的用户评分，除非您通过恶意评分来谋取利益并使平台无端遭受负面评价。</p>
		
		<p class="strong">九、第三方平台</p>
		
		<p>1．为向消费者提供更加丰富的购买渠道，本平台引入第三方平台。第三方平台在本平台发布预约购买跳转链接，您点击后即进入第三方平台的自有平台。</p>
		
		<p>2．您了解并确认，若您从第三方平台购买，您需要遵守第三方平台发布的用户协议、隐私政策或其他交易相关规则。对于第三方平台原因造成的用户信息泄露，您应直接向第三方平台主张您的权利。</p>
		
		<p>3．您同意并确认，您浏览第三方平台或向第三方平台预约购买商品和/或服务，出现问题时您应直接与第三方平台协商解决，但本平台会依据意见反馈机制积极协助消费者用户维护合法权益。</p>
		
		<p class="strong">十、用户个人信息保护</p>
		
		<p>本平台非常重视用户个人信息的保护。您知悉、理解并同意，在您购买本平台商品与/或使用本平台服务过程中，可能涉及收集、存储、使用、共享和保护用户个人信息。商品和/或服务具体关于如何处理和保护您的个人信息，请参考《三零荟隐私政策》。</p>
		
		<p class="strong">十一、网络安全</p>
		
		<p>1．本平台的服务器位于中华人民共和国境内，本平台的管理和运营发生在中华人民共和国上海市和贵州省。尽管从全世界均可访问本平台，但并非所有通过本平台或在本平台上讨论、提及、供应或提供的功能、商品和/或服务均可由所有人或在所有地理位置获得。本平台主要服务于在中华人民共和国（不包括香港特别行政区、澳门特别行政区和台湾省）境内有住所或者居住的用户。</p>
		
		<p>如果您位于上述地理范围之外的国家或地区并希望购买本平台的商品和/或服务，我们建议您在线选择并前往任何一家三零荟线下门店网点覆盖地区提货。</p>
		
		<p>2．您不可试图通过非法侵入、破解密码或任何其他非法方式，未经授权访问本平台的任何内容或购买本平台的任何商品与/或使用本平台服务。您不可追踪、反向查找、破解、破译本平台的任何客户的信息，包括但不限于任何非您所有的本平台账号。</p>
		
		<p>您不可违反本平台或链接至本平台的任何网络安全机制或认证措施，不可探测、扫描或者测试本平台或连接至本平台的任何网络弱点，您不可向本平台发起任何形式的网络攻击以及在本平台内或通过本平台散播病毒、僵尸和木马。</p>
		
		<p>您同意不使用任何设备、软件或程序，干扰或试图干扰本平台的正常运行或在本平台进行的任何交易，或干扰或试图干扰他人使用本平台。您不得采取任何行为，在本平台基础架构、系统或网络上，或链接至本平台的系统或网络上，存储不合理或不成比例的大量数据。</p>
		
		<p>3．本平台含有访问其他独立第三方平台的链接，该类链接仅为方便本平台的访问者而提供。本平台不对链接的内容、服务、信息等提供任何明示和默示的保证，此类链接也不视为本平台对链接的推荐、认可或授权，您需谨慎判断与此类链接的互动行为。</p>
		
		<p>4．您与本平台之间的网络连接、数据传输的安全性和保密性受您和您使用的电信运营商所采用的技术、或本平台之外的其他外部因素影响，尽管本平台采取了审慎的内部安全管理制度和操作流程及防范计算机病毒和网络攻击、网络侵入等危害网络安全行为的技术措施，您向本平台发送的信息、数据仍存在被他人拦截的可能性，本平台无法对您与本平台之间的网络连接、数据传输的安全性与保密性提供保证。</p>
		
		<p class="strong">十二、特别声明</p>
		
		<p>您未通过本平台进行的交易不属于本平台交易，本平台对此不承担任何责任，交易也不受本协议约束。不属于本平台交易的情况具体包括：您未在本平台与三零荟或其授权线下门店成立订单；您未通过本平台或者授权线下门店付款。</p>
		
		<p class="strong">十三、知识产权</p>
		
		<p>1．本平台所展示的各运营系统由三零荟自主开发、运营提供技术支持，并对本平台的开发和运营等过程中产生的所有数据和信息等（包括但不限于技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）和经我们创作、整理或以其他方式（如人工智能）生成内容的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密及相关权益）享有全部权利。除另有声明外，本平台提供商品和/或服务时所依托软件的著作权、专利权及其他知识产权权益归本平台、三零荟或相关权利人所有。本平台提供各项商品和/或服务相关的著作权、专利权，所使用的商标、商业形象、美术作品等知识产权的全部合法权利权益均归本平台、三零荟或相关权利人所有。</p>
		
		<p>2．除法律另有强制性规定外，未经本平台明确的特别书面同意，未经我们授权，任何单位或个人不得擅自使用（包括但不限于复制、传播、展示、镜像、上传、下载、修改、出租，以及通过任何机器人、爬虫、蜘蛛等任何自动程序、脚本、软件或设备进行操作等）亦不得修改、改编、翻译本平台所使用的软件、技术、材料等，或者创作与本平台相关的衍生作品或演绎作品，或者利用本平台之部分或全部向任何第三方提供商品和/或服务，不得通过反向工程、反编译、反汇编或其他类似行为获得本平台的源代码等。否则，我们有权追究您的责任，要求您赔偿由此给我们或其他人造成的损失，并有权视情况将上述行为移交相关政府部门处理。</p>
		
		<p>3．请您在任何情况下都不得私自使用、擅自删除、掩盖或更改我们的包括但不限于“三零荟”、“三个零”等品牌、商标在内的任何商业标识（包括我们及关联公司的Logo、“三零荟”、“三个零”等文字、图形及其组合，以及其他标识、徽记、商品和服务名称）、商号、域名、本平台名称或其他显著品牌特征等（以下简称“标识”）。未经我们的事先书面同意，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行著作权登记、进行域名注册等，也不得向他人明示或暗示您有权展示、使用、或其他有权处理这些标识，亦不得实施虚假或者引人误解的宣传，误使他人认为您与这些标识具有特定关联等行为。由于您违反本协议等给我们或他人造成损失的，应由您承担全部法律责任。</p>
		
		<p class="strong">十四、客户服务
		
		<p>为您提供及时的疑难解答与投诉反馈，您若有任何疑问可第一时间联系为您提供服务三零荟授权线下门店或本平台在线客服及本平台客服电话：18916620368/15208610109。</p>
		
		<p class="strong">十五、服务终止与协议终止</p>
		
		<p>1．本平台有权依据本协议约定或您要求，注销您的本平台账号，本协议于账号注销之日或本平台终止运营之日终止。</p>
		
		<p>2．本平台有权终止向您提供全部或者部分本平台商品和/或服务，本协议于本平台全部服务依法定程序及方式终止之日终止。该等情况下，本平台将提前三十日在本平台或客户端首页显著位置持续公示有关信息。您同意本平台不就终止本平台商品和/或服务而对您或任何第三方承担任何责任。</p>
		
		<p>3．本平台可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过法定程序并以本协议第十八条约定的方式通知您。如您不同意变更事项，您有权于变更事项确定的生效日前联系本平台反馈意见。如反馈意见得以采纳，本平台将酌情调整变更事项。如果您不同意本协议或其中任何条款或本协议后续的随时修订，您应当于变更事项确定的生效之日起停止使用本平台，变更事项对您不产生效力；如您在变更事项生效后仍继续购买本平台商品与/或使用本平台服务，则视为您同意已生效的变更事项。</p>
		
		<p>4．本协议终止后，您无权要求本平台继续向其提供任何服务或履行任何其他义务，包括但不限于要求本平台为您保留或向您披露其原本平台账号中的任何信息，向您或第三方转发任何其未曾阅读或发送过的信息等。</p>
		
		<p>5．本协议的终止不影响守约方向违约方追究违约责任。</p>
		
		<p class="strong">十六、违约责任</p>
		
		<p>1．如您违反本协议约定，以制假售假等目的进行交易，则本平台有权取消相关交易，并有权注销其本平台账号，终止为其提供本平台商品和/或服务，如本平台因此而遭受损失的，有权要求您赔偿损失。</p>
		
		<p>2．如因您提供的信息不真实、不完整或不准确给本平台造成损失的，本平台有权要求您赔偿由此给本平台造成的损失，本平台保留在法律允许的范围内因此而拒绝提供服务、删除其账号的权利。</p>
		
		<p>3．如因您违反法律法规规定或本协议约定，在本平台或利用本平台商品和/或服务从事非法活动的，本平台有权立即终止继续对其提供本平台商品和/或服务，注销其账号，并要求其赔偿由此给本平台造成的损失。</p>
		
		<p>4．如您以技术手段干扰本平台的运行或干扰其他用户购买本平台商品与/或使用本平台服务的，本平台有权立即注销其本平台账号，并有权要求其赔偿由此给本平台造成的损失。</p>
		
		<p>5．如您以虚构事实等方式恶意诋毁本平台的商誉或名誉，本平台有权要求您向本平台公开道歉，赔偿其给本平台造成的损失，并有权终止对其提供本平台商品和/或服务。</p>
		
		<p>6．如您存在其他违反有关法律法规规定或违反本协议约定的情形的，本平台有权利根据法律法规和本协议约定，采取违约处理措施（包括但不限于对相关内容进行删除、屏蔽，并视行为情节对违规账号处以包括但不限于警告、限制或禁止使用部分或全部功能、账号封禁、注销等处罚，并公告处理结果）。如您的行为使本平台及其关联公司遭受损失（包括自身的直接经济损失、商誉损失和对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应向本平台及其关联公司赔偿上述全部损失。如您的行为使本平台及其关联公司遭受第三人主张权利，本平台及其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。</p>
		
		<p class="strong">十七、责任限制</p>
		
		<p>1．您理解并同意，本平台在“按现状”和“按现有”的基础上，并会在现有技术水平和条件下尽最大努为您提供合法的商品和/或服务，但是您应对其使用本平台及相关关联公司链接本平台自行承担责任及风险。本平台全力向您提供服务，确保服务的连贯性和安全性，但不能随时预见和防范法律、技术以及其他风险的除外，包括但不限于以下原因：</p>
		
		<p>1.1 自然灾害、罢工、暴乱、战争、政府行为、司法行政命令、传染病疫情（如COVID-19）不可抗力因素；</p>
		
		<p>1.2 电力供应故障、通讯网络故障等公共服务因素或第三方因素；</p>
		
		<p>1.3 在本平台已尽善意管理义务的情况下，常规或紧急的信息网络设备维护、信息网络连接故障、电脑、通讯或其他系统故障、大规模的网络病毒、木马和黑客攻击、网络信息与数据安全等因素；</p>
		
		<p>1.4 本平台出于自身商业决定、平台维护需要等原因，在法律允许范围内调整、更改、暂停服务；</p>
		
		<p>1.5 您操作不当、通过非本平台授权的方式使用服务及其他本平台无法控制或合理预见的情形。</p>
		
		<p>对于以上原因及其他不可抗力或第三方因素等而导致的合同履行障碍、履行瑕疵、履行延迟、履行内容变更等情形，本平台不承担责任。</p>
		
		<p>2． 除非另有明确书面说明，在符合法律法规规定的情况下，本平台不对其本平台上的信息、内容、材料、商品和/或服务做任何形式的明示或默示的声明或担保，包括但不限于稳定、正确、及时、完整、连贯、安全等，对此您应谨慎判断，但我们承诺将不断提升服务质量及服务水平，为您提供更加优质的服务。</p>
		
		<p>3． 除在法律允许的范围内，本平台及其关联公司对于本协议项下任何索赔的全部赔偿责任限于您因使用服务而向我们支付的订单金额。在任何情况下，不论是否可以预见，不论是源于何种形式的行为，我们不对由任何原因造成的特别的、间接的、惩罚性的、突发性的或有因果关系的损害或其他任何损害（包括但不限于利润、商誉、使用、数据或利息的损失、经营中断、资料灭失）承担责任。</p>
		
		<p>4． 由于技术本身的局限性，即便我们已经做出最大努力，我们不能保证我们的本平台与其他软硬件、系统完全兼容。如果出现不兼容的情况，您可以向客服咨询，以获得技术支持。如果无法解决问题，您可以选择关闭、停止使用本平台。如因使用本平台而对计算机系统造成的损坏或数据的丢失等，您须自行承担全部责任。</p>
		
		<p>5． 本平台将尽可能保证服务的及时性、安全性、准确性，但我们不保证服务一定能满足每个用户的要求，也无法保证服务一定不会中断。为了保障本平台正常运行，本平台可能会对本平台进行定期或针对突发事件的维护。因上述情况造成的服务中断，本平台不承担损失赔偿责任。</p>
		
		<p>因电力信号中断、表达偏差、操作失误、网络故障、网络中断、电脑病毒、非故意或重大过失遗漏、人为破坏或任意未经授权的访问、篡改或使用而造成的任何损失、责任或伤害，您理解上述情形无法完全避免，您将不会因此追究本平台的责任。</p>
		
		<p>6． 您理解并同意，为了完善平台功能、改善用户体验、保障本平台运营商品和/或服务的安全性，我们可能会定期或不定期对本平台进行更新并有权自行决定进行业务变更、调整及技术变更等，有权视情况随时修改或中断、中止或终止本平台而无需通知您，也无需向您或第三方负责或承担任何赔偿责任，除非法律另有规定或双方另有约定。</p>
		
		<p>7． 根据法律法规的要求，我们依据其自身判断，对于您的任何违反或涉嫌违反中国法律或本协议约定的内容，有权视情节删除、屏蔽或断开链接；停止为您提供服务或予以封禁账号；赔偿我们与/或上述第三方遭受的损失；并有权依据中国法律法规之规定保存有关信息并向相关政府部门进行报告。</p>
		
		<p>8． 本平台依据本协议约定获得处理违法违规内容的权利，该权利不构成本平台的义务或承诺，本平台不能保证及时发现违法行为或进行相应处理。在客服争议调处服务中，本平台的客服并非专业人士，仅能以普通人的认知对您提交的凭证进行判断。因此，除存在故意或重大过失外，调处方对争议调处决定免责。</p>
		
		<p>9． 对于任何第三方向本平台提出的，因您不正确使用本平台而产生的或与之有关的任何请求、损失、责任、主张或费用(包括但不限于律师费、公证费等)，您同意依据法律的规定，赔偿本平台及其关联公司的损失。</p>
		<p>10． 国家法律法规规定的其他免责事由。</p>
		
		<p class="strong">十八、通知</p>
		
		<p>1． 有效联系方式</p>
		
		<p>您在注册成为本平台用户，并接受本平台商品和/或服务时，您应该向本平台提供真实有效的联系方式（包括您的手机号码等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。您在注册本平台用户时生成的用于登录的本账号，也作为您的有效联系方式。</p>
		
		<p>本平台将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</p>
		
		<p>您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的商品和/或服务信息、促销优惠等商业性信息；您如果不愿意接收此类信息，您有权通过本平台提供的相应的退订功能进行退订。</p>
		
		<p>2．通知的送达</p>
		
		<p>本平台通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在本平台公告，向您提供的联系电话发送手机短信向您的账号发送站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。</p>
		
		<p>对于在本平台上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件、站内信等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱或本账号等联系方式为您在本平台注册、更新时提供的手机号码、电子邮箱联系方式以及在注册本用户时生成本账号，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。</p>
		
		<p>您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。您应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。</p>
		
		<p>十九、法律管辖适用和其他</p>
		
		<p>1． 本协议签订地为中华人民共和国上海市虹口区。本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例或行业惯例。</p>
		
		<p>2． 您与本平台因本协议的履行发生争议的，应通过友好协商解决，协商解决不成的，任何一方均应向合同签订地有管辖权的人民法院提起诉讼。</p>
		
		<p>3． 本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
		
		<p>4． 本协议未明示授权的其他权利仍由本平台及三零荟保留，您在行使这些权利时须另外取得本平台及三零荟的书面许可。本平台及三零荟如果未行使前述任何权利，并不构成对该权利的放弃。</p>
		
	</div>
</div>
</template>

<script>
	
	export default {
	  name: 'index',
	  components: {
	  },
	  data() {
		return {
		  title:"三个零",
		}
	  },
	  created() {
		
	  },
	  methods: {

	  } 
	}
</script>

<style>
	p{
		margin: 5px 0;
		line-height: 1.5;
	}
	.container {
	  padding: 10px;
	}
	.strong{
		font-weight: bold;
	}
	.item{
		margin-top: 10px;
	}
</style>
